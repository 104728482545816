<template>
    <div>
        <v-toolbar
                dense
                tabs
                color="primary darken-2"
                dark
        >
            <v-toolbar-title class="pa-0 text-xs-left">
                <a href="javascript: history.go(-1)" class="pa-0">
                    <v-btn flat fab small class="pa-0">
                        <i class="fas fa-arrow-left headline"></i>
                    </v-btn>
                </a>
                 Funding: Legal Bindings
            </v-toolbar-title>
        </v-toolbar>

        <v-container>
            <v-layout row>
                <v-flex xs12 md8 offset-md2>
                    <v-slide-y-transition mode="out-in">
                        <router-view/>
                    </v-slide-y-transition>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>

</style>
